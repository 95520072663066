.map{
    min-height: 500px;
    h1{
        font-size: 100px;
        margin: 6px;
        margin-left: 5% !important;
    }
p{
    line-height: 1.5rem;
}
.globe{
    margin-top: -8% !important;
    div{
        height: 100% !important;
    }
}


@media screen and (min-width: 1608px) {
    .globe{
    margin-top: -22% !important;
    }
}

@media screen and (max-width: 908px) {
    .globe{
    display: none !important;
    }
}


}

.snd{
    color: rgb(146, 146, 146);
background-color: rgb(16, 17, 17);

}

.thrd{
    color: rgb(146, 146, 146);

}

.bg{
    background-image: linear-gradient(to right, rgba(22, 28, 31, 0.8), rgba(7, 7, 10, 0.7)), url('../../Assets/bghome.png');

    backdrop-filter: blur(2px) brightness(0.6);

    min-height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -1%;
    padding-top: 3%;
    color: white;


    p{
        color: white;
    }
        button{
            color: white !important;
            border-color: white !important;
        }
    
}