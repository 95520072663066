@import url("https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@100..800&family=Fenix&family=Gowun+Dodum&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap" );
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Heading';
  src: url('../src/Assets/fonts/heading.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nc';
  src: url('../src/Assets/fonts/Coco.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: "Heading",' serif',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  line-height: initial !important;
  background-color: rgb(26, 26, 27);
  /* background: linear-gradient(to bottom right, rgba(7, 7, 12, 0.5), rgba(1, 82, 71, 0.1));  */
overflow-x: hidden ;
width: 100vw;

}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(66, 66, 66); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(1, 50, 83); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #018040; 
}

code {
  font-family: 'Cormorant Garamond',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: inherit;
    text-decoration: none;
    font-family: 'Cormorant Garamond',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.twh{
  color: white;
}

.pt10p{
  padding-top: 10%;
}

.pt17p{
  padding-top: 17%;
}

.w70p{
  width: 70%;
}

.divider{
  /* width: 14%; */
  color: burlywood;
  /* margin: 0 5%; */
  background-color: rgb(233, 181, 122);
  width: 15%;
  border: 1px solid rgb(233, 181, 122);
}

.bgmain{
  background-color: rgb(233, 181, 122) !important;

}

.p10p{
  padding: 10%;
}

.p010p{
  padding: 1% 10%;
}

.w50p{
  width: 50%;
}

.bold{
  font-weight: 600;
}

.tu{
  text-decoration: underline;
}

.dfi{
  display: flex;
  align-items: center;
}

@media screen and (max-width:880px) {
.jcc{
  justify-content: center;
  display: flex;
}

.dfi{
  display: grid;
  text-align: center;
  margin-bottom: 2%;
}

}

.slider{
  /* max-width: 700px; */
  /* height: 400px; */
 
}

.slick-slider{
  width: 700px !important;
  height: 550px !important;
}

.slick-slider  img{
  /* width:400px ; */
  height: 550px;
}

.slick-prev, .slick-next{
  display: none !important;
}

.mt15{
  margin-top: 5%;
}

.mt10p{
  margin-top: 10% !important;
}

.mt5p{
  margin-top:  5% !important;
}
.pl{
  padding-left: 10px;
}

/* .custom-shadow{
  box-shadow:  20px 20px 60px #686868,
  -20px -20px 60px #b1a3a3;
} */


.blurs{
  backdrop-filter: blur(10px);
}