.projects {
  // Your section-level styles here
  justify-content: center;
  // background: #161616;
  // border-radius: 3vw;
  // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('../../Assets/main.jfif');

  background-repeat: no-repeat;
  background-size: cover;
  color: white;
overflow-x: hidden;
  // min-width: 100%;
  .overlay {
    backdrop-filter: blur(20px);
    // height: 2200px ;
    padding: 1% 3%;

  }

  .secondoverlay {
    backdrop-filter: blur(10px);
    background: linear-gradient(175deg, rgba(41, 41, 41, 0.8) 0%, rgba(17, 17, 17, 0.8) 50%, rgba(0, 0, 0, 0.8) 100%);
    // height: 2000px;
    padding: 3%;
    border-radius: 3vw;
    margin-top: 1%;

  }



  h1 {
    font-size: 50px;
  }
}

.project-tags {
  display: flex;
  gap: 20px;
  margin-bottom: 0px;
  justify-content: center;
  color: #555 !important;

  span {
    color: #333333;
  }
}

.tag {
  padding: 6px 12px !important;
  background-color: transparent !important;
  cursor: pointer !important;
  border-radius: 10px !important;
  // box-shadow: 0 4px 6px rgba(121, 120, 120, 0.5);
  color: #7a7a7a !important;

}
.active{
  color:white !important;
  border-bottom: 1px solid white !important;
}
.tag :hover {}

.active {
  background-color: #333333;
}

.project-cards {
  grid-gap: 20px;
  gap: 90px;

  display: flex;
  justify-content: center;
}

.project-card {
  // border: 1px solid #ccc;
  border-radius: 26px;
  // overflow: hidden;
  // box-shadow: 0 4px 6px rgba(168, 168, 168, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 400px;
  margin-top: 6%;
  background-color: rgba(43, 42, 42, 0.0);
  min-height: 440px;
  max-height: 440px;
  overflow: hidden;
}


.project-image {
  position: relative;
  background-color: rgba(43, 42, 42, 0.2);

  // height: 50%; /* Display only half of the image initially */
  // overflow: hidden; /* Prevent image from overflowing */
  transition: height 0.5s ease;
  /* Add a smooth transition effect */
  height: 280px;
  min-height: 280px;
  padding: 6%;


  img {
    width: 100%;
    height: auto;
    transition: transform 1s linear;
    /* Adjust the scrolling speed */
    transform: translateY(0);
    /* Start position at the top */
    height: 100%;
  }

  &:hover {
    // height: 50%; /* Expand to show the full image */

    img {
      transform: scale(1.1);
      /* Scroll the image up on hover */
    }
  }
}



.project-details {
  padding: 20px;
  padding-top: 0px;
  background-color: transparent;

  h3 {
    margin-bottom: -8px;
    margin-top: 7px;
  }

  p {
    color: #ffffff;
  }

  .category-tag {
    padding: 4px 8px !important;
    background-color: #f0f0f0;
    border-radius: 4px !important;
    font-size: 12px;
    color: #000000;
    font-weight: 600;
  }


}

.red {
  // background: linear-gradient(175deg, rgba(159,28,28,0.4) 0%, rgba(199,3,96,0.5) 50%, rgba(255,0,78,0.3) 100%);
  background: transparent !important;

  transform: scale(1.1);
  box-shadow: none;
  padding: 15px;
}






@media screen and (max-width:880px) {

  .slick-slider{
    width: fit-content !important;
    img{
      width: 300px !important;
    }
  }

  .search{
    width: 70% !important;
  }

  .red {
    display: none !important;
  }

  .projects {
    width: 100%;
  }
}

.inquiry {
  p {
    font-size: 16px;

  }

  // button {
  //   background: linear-gradient(175deg, rgba(159, 28, 28, 0.837) 0%, rgba(199, 3, 45, 0.8) 50%, rgba(255, 0, 78, 0.7) 100%);
  //   ;
  //   color: white !important;
  //   padding: 4px 8px !important;
  // }
}