.contact{
    // Your section-level styles here
    justify-content: center;
    // background: #161616;
  // border-radius: 3vw;
  background-image: linear-gradient(to right, rgba(110, 110, 110, 0.8), rgba(0, 0, 0, 0.9)), url('../../Assets/bgcontact.png  ');

background-repeat: no-repeat;
background-size: cover;
color: white;
min-width: 100vw;
.overlay{
  backdrop-filter: blur(10px) ;
// height: 2200px ;
padding: 1% 3%;
border-radius: 25%;


}

}

.getin{
  // display: flex;
  // justify-content: center;
  box-shadow: rgb(83, 83, 83) 3px 3px 6px 0px inset, rgba(110, 110, 110, 0.5) -3px -3px 6px 1px inset;

  padding: 1% 4%;
  align-items: center;
  height: 100%;
  margin-bottom: 10%;
  input{
    color: white !important;
    border-bottom: 1px solid white;
  }
  textarea{
    border-bottom: 1px solid white;
color: white;
  }
  textarea::after{
    border-bottom: 1px solid white;
  }
  label{
    color: white;
  }
  .MuiInput-input::before{
    border-bottom: 1px solid white !important;
  }
  .MuiInput-input::after{
    border-bottom: 1px solid rgb(255, 240, 214) !important;
  }
  h2{
    font-size: 22px;
  }
}
.neon {
  color: #fff;
  font-size: 35px;
  text-shadow:
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 20px #fff,
    0 0 80px rgb(255, 145, 0),
    0 0 80px #0ff,
    0 0 70px #0ff,
    0 0 110px rgb(255, 136, 0),
    0 0 140px #0ff;
} 

.info {
    //  background: linear-gradient(175deg, rgba(14, 25, 61, 0.5) 0%, rgba(26, 39, 58, 0.2) 50%, rgba(53, 69, 88, 0.2) 100%);
    padding-left: 5%;
     h2{
      font-size: 22px;
      color: beige;
    }
    p{
      margin: 1px !important;
    }
    .phone{
      padding-top: 1%;
      color:rgb(255, 136, 0) !important;
      font-family: 'Courier New', Courier, monospace !important;
      display: flex;
      align-items: center;
      svg{
        color: rgb(255, 136, 0);
        padding-right: 2%;
        font-size: 30px;
      }

    }
  .stack{
    justify-content: space-between;
    font-family: none;
    font-size: 35px;
    width: 280px;
    align-items: center;
    p{
      margin: 1px !important;
    }
   
  }
}